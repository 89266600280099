import { PositionPrecisionEnum, PositionSourceEnum } from '@wheere/api-tracker';

export function getModStatusValue(enumValue?: number) {
  return Object.keys(PositionPrecisionEnum).find(
    key => PositionPrecisionEnum[key as keyof typeof PositionPrecisionEnum] === enumValue
  );
}

export function getPositionPrecision(status: number): PositionPrecisionEnum {
  switch (status) {
    case 255:
      return PositionPrecisionEnum.INVALID;
    case 0:
      return PositionPrecisionEnum.NOTFIXED;
    case 1:
      return PositionPrecisionEnum.IDANTENNA;
    case 2:
      return PositionPrecisionEnum.FREQFIXED;
    case 3:
      return PositionPrecisionEnum.COARSE_PRECISION;
    case 4:
      return PositionPrecisionEnum.LOW_PRECISION;
    case 5:
      return PositionPrecisionEnum.NORMAL_PRECISION;
    case 6:
      return PositionPrecisionEnum.FAKE;
    default:
      throw new Error(`Invalid status value: ${status}`);
  }
}

export function getPositionPrecisionString(status: number | undefined): string {
  switch (status) {
    case 255:
      return 'INVALID';
    case 0:
      return 'NOTFIXED';
    case 1:
      return 'IDANTENNA';
    case 2:
      return 'FREQFIXED';
    case 3:
      return 'COARSE_PRECISION';
    case 4:
      return 'LOW_PRECISION';
    case 5:
      return 'NORMAL_PRECISION';
    case 6:
      return 'FAKE';
    default:
      return 'undefined';
  }
}
